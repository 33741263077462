import { type PropsWithChildren } from 'react'
import { type Role, useHasRole } from '#app/utils/permissions.ts'

export function HasRole({
	roles,
	children,
}: PropsWithChildren<{ roles: Role[] }>) {
	const hasRole = useHasRole(roles)

	if (hasRole || !roles || roles.length === 0) {
		return children
	}

	return null
}
